const config = {
    apiUrl: 'https://trump-backend-dot-trump-deploy.uw.r.appspot.com',
    defaultLimit: 10,
    defaultOffset: 0,
    routeNames: {
        subjects: 'subjects',
        topics: 'topics'
    },
    dataNames: [
        {
            subjects: 'phrases',
            subject: 'phrase',
            topics: '',
            isSubjectToCount: "yes",
            topicsSentiments: ['positive'],
            aiFields: {
                explanation: "explanation",
                sentenceExample: "sentenceExample",
                translations: "translations",
                similarPhrases: "similarPhrases",
            },
            aiLanguages: [
                { code: 'zh', name: 'Chinese', word: '中文' },
            ]    
        }
    ],
    defaultSecondLanguage: 'zh',
    languages: [
        { code: 'en', name: 'English' },
        // { code: 'zh', name: '中文' }
    ],
    videoWatchSeconds: 30
};
  
export default config;
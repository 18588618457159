import React, { useState, useEffect } from 'react';
import { Box, Menu, MenuItem, IconButton, Checkbox, ListItemText } from '@mui/material';
import { useLocation } from 'react-router-dom';
import LanguageIcon from '@mui/icons-material/Language';
import config from '../config';

const languages = config.dataNames[0].aiLanguages;

const AiLangs2 = ({ onLanguageChange }) => {
  const location = useLocation();
  const [selectedLangs, setSelectedLangs] = useState(() => {
    const aiParam = new URLSearchParams(location.search).get('aiLangs');
    if (aiParam) {
      return aiParam.split(',');
    }    
    const storedLangs = localStorage.getItem('selectedLangs');
    return storedLangs ? storedLangs.split(',') : [config.defaultSecondLanguage];
  });

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    localStorage.setItem('selectedLangs', selectedLangs.join(','));
    onLanguageChange(selectedLangs.join(','));
  }, [selectedLangs, onLanguageChange]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (langCode) => {
    setSelectedLangs(prev => 
      prev.includes(langCode) ? prev.filter(l => l !== langCode) : [...prev, langCode]
    );
  };

  return (
    <Box>
      <IconButton onClick={handleClick}>
        <LanguageIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {languages.map((lang) => (
          <MenuItem key={lang.code} onClick={() => handleLanguageChange(lang.code)}>
            <Checkbox checked={selectedLangs.includes(lang.code)} />
            <ListItemText primary={lang.word} secondary={lang.name} />
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default AiLangs2;